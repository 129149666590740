<template>
	

	<section style="padding-top:100px; padding-bottom: 20px;">
		

		<div class="container">

			<div class="row">

				<div class="col-md-2"></div>

				<div class="col-md-4" style="background-image: url('/images/cartoon-doctors-nurses-collection-illustration_23-2148920402.jpg'); background-size: cover; border-radius: 0px;"></div>
				<div class="col-md-4">
					
					<div style="padding:25px; border: solid 1px #ddd;">
						
						<div style="text-align: center;">
							<img src="/images/KOGS-Logo-1-01.png" style="height: 70px;">
							<h3> {{this.$store.state.organization_name}} </h3>
						<h6>Forgot Membership Number</h6>
						</div>

						<form @submit.prevent="forgot_number()">
							
							<div>
								<label>Email address <span class="text-danger">*</span> </label>
								<input type="text" v-model="email" class="form-control" placeholder="Enter email address">
							</div>


						

							<div class="mt-3" style="text-align:center;">
								<button class="btn btn-1 w-100" :disabled="disabled">{{text}}</button>

								<router-link to="/" class="btn btn-danger mt-3 w-100">Login</router-link>
								
							</div>

					

							<div style="text-align:center; padding-top: 20px;">
								<p class="color-3">MMS.All rights reserved</p>
							</div>

						</form>

					</div>

				</div>
				
			</div>
			
		</div>


	</section>


</template>







<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'register',
		data(){
			return {
				email : '',
				text : 'Submit',
				disabled : false			}
		},

		methods : {

			async load_settings(){
				const res = await axios.get(this.$store.state.url+'api/load-settings').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.$store.state.year_starting_day = data.year_starting_day 
					localStorage.setItem('year_starting_day',data.year_starting_day)

					this.$store.state.year_starting_month = data.year_starting_month 
					localStorage.setItem('year_starting_month',data.year_starting_month)

					this.$store.state.organization_name = data.organization_name 
					localStorage.setItem('organization_name',data.organization_name)


				})
			},

			async forgot_number(){
				
				if(!this.email){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Enter email address',
					  showConfirmButton: true,
					  
					})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/forgot-number',{

					email : this.email,

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='error'){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Email does not exist',
					  showConfirmButton: true,
					  
					})
				}else{
					
					Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title : 'Success',
					  text: res,
					  showConfirmButton: true,
					  
					})
				}

				this.text = 'Submit'
				this.disabled = false

				
				}


		},

		created(){

this.load_settings()

		}

	}
</script>

