<template>
	<div class="navcontainer"> 
			<nav class="nav"> 
				<div class="nav-upper-options"> 
					<div class="nav-option"> 
					<router-link class="btn option1" to="/dashboard"><i class="bi bi-speedometer"></i> Dashboard</router-link> 
					</div> 


		
					<!--
		<div class="nav-option">
	<div class="dropdown">
  <button class="btn option1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
     <i class="bi bi-layers"></i> Apartments
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
   
    <li><router-link to="/all-apartments" class="dropdown-item">View  apartments</router-link></li>

  </ul>
</div>
</div>-->




					<div class="nav-option"> 
					<router-link class="btn option1" to="/card"><i class="bi bi-person-vcard"></i> Membership card</router-link> 
					</div>

						<div class="nav-option"> 
					<router-link class="btn option1" to="/invoices"><i class="bi bi-currency-dollar"></i> Invoices</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link class="btn option1" to="/payments"><i class="bi bi-wallet"></i> Payments</router-link> 
					</div>

						<div class="nav-option"> 
					<router-link class="btn option1" to="/activities"><i class="bi bi-list"></i> Activities</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link class="btn option1" to="/resources"><i class="bi bi-database-gear"></i> Resources</router-link> 
					</div>


					<div class="nav-option"> 
					<router-link class="btn option1" to="/memos"><i class="bi bi-grid"></i> Memos</router-link> 
					</div>


	<div class="nav-option"> 
	<router-link class="btn option1" to="/profile"><i class="bi bi-gear"></i> Profile</router-link> 
	</div>



						<div class="nav-option"> 
					<button @click="logout()" class="btn option1"><i class="bi bi-power"></i> Log Out</button> 
					</div> 


					
				</div> 
			</nav> 
		</div>
</template>


<script>
	export default{

		name : 'sidebar',

		methods : {
			logout(){
				localStorage.clear()
				this.$router.push('/')
			}
		}

	}

</script>