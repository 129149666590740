<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Activity details</h3>
					</div>


					<div class="shadow rounded p-3">

						<div style="text-align: right;">
							
						</div>



						<!--details -->

						<router-link to="/activities" class="btn p-0 mb-3 color-1"> <i class="bi bi-chevron-left"></i> Go Back</router-link>

						<h4> {{title}} </h4>
						<h5> <i class="bi bi-calendar-check"></i>  {{date}} to {{end_date}} </h5>

						<div style="border-top:solid 1px #ddd;"></div>

						<h6 class="mt-2">
						<span class="color-3">Location:</span><br />
						{{location}}

						</h6>


						<h6>
						<span class="color-3">Description:</span><br />
						{{description}}

						</h6>


						<h6>
						<span class="color-3">Link:</span><br />
						{{link}}

						</h6>
						
						
						
						
					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			id : '',
			text : 'Save changes',
			disabled : false,
			title : '',
			date : '',
			cpd : '',
			description : '',
			location : '',
			link : '',
			end_date : ''		
			}
		},

		methods : {

			async get_details(){

				const res = await axios.get(this.$store.state.url+'api/activity-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.title = data.title 
					this.date = data.activity_date
					this.end_date = data.end_date
					this.cpd_points = data.cpd_points
					this.location = data.location
					this.link = data.link
					this.description = data.description
				})

				
			}
		
		},

		created(){

			this.id = this.$route.params.id
			this.get_details()

		}

	}

</script>