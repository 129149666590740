<template>
	

	<section style="padding-top:50px; padding-bottom: 20px;">
		

		<div class="container">

			<div class="row">
				<div class="col-md-2"></div>

				<div class="col-md-4" style="background-image: url('/images/cartoon-doctors-nurses-collection-illustration_23-2148920402.jpg'); background-size: cover; background-position: center;"></div>
				
				<div class="col-md-4">
					
					<div style="padding-top: 20px; padding-bottom: 20px; border: solid 1px #ddd; padding-left:20px; padding-right: 20px;">
						
						<div style="text-align: center;">
							<img src="/images/KOGS-Logo-1-01.png" style="height: 70px;">
							<h6 class="color-1">Welcome Back!</h6>
							<h4> {{this.$store.state.organization_name}} </h4>
						
						<h5>Login</h5>
						</div>

						<form @submit.prevent="login()">
							
							<div>
							
								<input type="text" v-model="membership_number" class="form-control" placeholder="Your membership number">
							</div>


							<div class="mt-2">
							
								<input type="password" v-model="password" class="form-control" placeholder="Your password">
							</div>

							<div class="mt-3" style="text-align:center;">
								<button class="btn btn-1 w-100" :disabled="disabled">{{text}}</button>
								<div class="mt-2 mb-2">
									<router-link to="/forgot-pwd" class="btn">Forgot my password</router-link>
								</div>
								<router-link to="/packages" class="btn btn-3 w-100">Register</router-link>
							</div>

							<div style="padding-top:10px; text-align: center;">

								<div class="row">

									<div class="col-md-12">
										<router-link to="/forgot-number" class="btn">Forgot membership number</router-link>
									</div>
									
								</div>

							</div>

							<div style="text-align:center;">
								<p class="color-3">MMS.All rights reserved</p>
							</div>

						</form>

					</div>

				</div>
				
			</div>
			
		</div>


	</section>


</template>







<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'register',
		data(){
			return {
				membership_number : '',
				password : '',
				text : 'Login',
				disabled : false,
				today : ''
			}
		},

		methods : {

			async load_settings(){
				const res = await axios.get(this.$store.state.url+'api/load-settings').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.$store.state.year_starting_day = data.year_starting_day 
					localStorage.setItem('year_starting_day',data.year_starting_day)

					this.$store.state.year_starting_month = data.year_starting_month 
					localStorage.setItem('year_starting_month',data.year_starting_month)

					this.$store.state.organization_name = data.organization_name 
					localStorage.setItem('organization_name',data.organization_name)


				})
			},

			async package_details(){
				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.$store.state.package).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.$store.state.package_name = data.name 
					localStorage.setItem('package_name',data.name)
				})
			},

			async login(){
				
				if(!this.membership_number){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Enter membership number',
					  showConfirmButton: true,
					  
					})
					return
				}

				if(!this.password){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Enter password',
					  showConfirmButton: true,
					  
					})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'api/member-login',{

					membership_number : this.membership_number,
					password : this.password,
		

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res.length>0){

					res.forEach((data)=>{
						localStorage.setItem('id',data.id)
						this.$store.state.id = data.id

						localStorage.setItem('title',data.title)
						this.$store.state.title = data.title

						localStorage.setItem('membership_number',data.membership_number)
						this.$store.state.membership_number = data.membership_number

						localStorage.setItem('first_name',data.first_name)
						this.$store.state.first_name = data.first_name

						localStorage.setItem('last_name',data.last_name)
						this.$store.state.last_name = data.last_name

						localStorage.setItem('email',data.email)
						this.$store.state.email = data.email

						localStorage.setItem('phone',data.phone)
						this.$store.state.phone = data.phone

						localStorage.setItem('county',data.county)
						this.$store.state.county = data.county

						localStorage.setItem('address',data.address)
						this.$store.state.address = data.address

						localStorage.setItem('photo',data.photo)
						this.$store.state.photo = data.photo

						localStorage.setItem('status',data.status)
						this.$store.state.status = data.status

						localStorage.setItem('expires_on',data.expires_on)
						this.$store.state.expires_on = data.expires_on


						localStorage.setItem('created',data.created)
						this.$store.state.created = data.created

						localStorage.setItem('package',data.package)
						this.$store.state.package = data.package


						localStorage.setItem('qr_code',data.qr_code)
						this.$store.state.qr_code = data.qr_code

						localStorage.setItem('full_name',data.full_name)
						this.$store.state.full_name = data.full_name

						localStorage.setItem('gender',data.gender)
						this.$store.state.gender = data.gender

						localStorage.setItem('postal_address',data.postal_address)
						this.$store.state.postal_address = data.postal_address

						localStorage.setItem('postal_code',data.postal_code)
						this.$store.state.postal_code = data.postal_code

						localStorage.setItem('office_address',data.office_address)
						this.$store.state.office_address = data.office_address

						localStorage.setItem('full_name',data.full_name)
						this.$store.state.full_name = data.full_name

						localStorage.setItem('town',data.town)
						this.$store.state.town = data.town

						localStorage.setItem('country',data.country)
						this.$store.state.country = data.country

						localStorage.setItem('kmpdc',data.kmpdc)
						this.$store.state.kmpdc = data.kmpdc

						localStorage.setItem('organization',data.organization)
						this.$store.state.organization = data.organization



						if( new Date(this.today) > new Date(data.expires_on) ){
						

						this.$router.push('/renew')

						}else if(data.status!='Active'){
						Swal.fire({
					  position: "top-center",
					  icon: "info",
					  title : 'Please note',
					  text: 'You cannot login because your account is not active',
					  showConfirmButton: true,
					  
					})	
						}else{
						localStorage.setItem('logged_in','yes')
						this.$store.state.logged_in = 'yes'

						this.package_details()

						this.$router.push('/dashboard')
						}
						

					})

				}else{
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Your login credentials are invalid',
					  showConfirmButton: true,
					  
					})
				
				}


				this.text = 'Login'
				this.disabled = false

				
			}


		},

		created(){
const date = new Date()
let day = date.getDate()
let month = date.getMonth() + 1;
let year = date.getFullYear()
this.today = `${year}-${month}-${day}`

this.load_settings()

		}

	}
</script>

