import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://mmsserver.kogs.or.ke/',
    id : localStorage.getItem('id'),
    membership_number : localStorage.getItem('membership_number'),
    title : localStorage.getItem('title'),
    first_name : localStorage.getItem('first_name'),
    last_name : localStorage.getItem('last_name'),
    email : localStorage.getItem('email'),
    phone : localStorage.getItem('phone'),
    county : localStorage.getItem('county'),
    address : localStorage.getItem('address'),
    logged_in : localStorage.getItem('logged_in'),
    photo : localStorage.getItem('photo'),
    status : localStorage.getItem('status'),
    expires_on : localStorage.getItem('expires_on'),
    created : localStorage.getItem('created'),
    selected_package : localStorage.getItem('selected_package'),
    package : localStorage.getItem('package'),
    package_name : localStorage.getItem('package_name'),
    cost : localStorage.getItem('cost'),
    year_starting_day : localStorage.getItem('year_starting_day'),
    year_starting_month : localStorage.getItem('year_starting_month'),
    organization_name : localStorage.getItem('organization_name'),
    qr_code : localStorage.getItem('qr_code'),
    full_name : localStorage.getItem('full_name'),
    gender : localStorage.getItem('gender'),
    speciality : localStorage.getItem('speciality'),
    kmpdc : localStorage.getItem('kmpdc'),
    country : localStorage.getItem('country'),
    town : localStorage.getItem('town'),
    kmpdc : localStorage.getItem('kmpdc'),
    office_address : localStorage.getItem('office_address'),
    postal_address : localStorage.getItem('postal_address'),
    postal_code : localStorage.getItem('postal_code'),
    organization : localStorage.getItem('organization')


  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
