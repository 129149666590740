<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Invoices</h3>
					</div>


					<div class="shadow rounded p-3">
						
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
								<th> # </th> <th> Membership number </th> <th>Name</th> <th> Invoice year </th> <th>Package</th> <th> Amount </th> <th> Status </th> <th>Invoice date</th>	
								</tr>
							</thead>


							<tbody>
								
								<tr v-for="i in details">
									<td> {{i.id}} </td>
									<td> {{i.membership_number}} </td>
									<td> {{i.first_name}} {{i.last_name}} </td>
									<td> {{i.year}} </td>
									<td> {{i.package_name}} </td>
									<td> Ksh.{{i.cost}} </td>
									<td> {{status}} </td>
									<td> {{i.created}} </td>
								</tr>

							</tbody>
							
						</table>
						

					</div>

					</div>







					<div class="shadow rounded p-3 mt-3">

						<div class="mb-3" v-if="balance>0">
							<button @click="stk_push()" class="btn btn-outline-success">Pay invoice + </button>
						</div>

						
						<div class="mb-3" v-if="balance<=0 && show_message ">
							<div class="row" style="background-color:#fafafa; border-left: solid 4px green; padding: 10px;">

								<div class="col-md-1 col-2" style="text-align:center;">
									<h3><i class="bi bi-info-circle"></i></h3>
								</div>

								<div class="col-md-11 col-10" style="border-left: 1px #ddd solid;">
									<p>This invoice has fully been paid</p>
								</div>
								
							</div>
						</div>


						<h6>Payment records</h6>


						<div class="table-responsive">

							<table class="table">

								<thead>
									<tr>
									<th> # </th>	<th> Amount </th> <th> Payment mode </th> <th> Payment date </th> <th> Comments </th>
									</tr>
								</thead>

								<tbody>
									
									<tr v-for="p in payments">
										<td>  {{ p.id }}   </td>
										<td> Ksh.{{ p.amount }} </td>
										<td> {{ p.payment_mode }} </td>
										<td> {{ p.payment_date }} </td>
										<td> {{ p.comments }} </td>
									</tr>

								</tbody>
								
							</table>
							
						</div>




						<div>
							<h6> Total paid : Ksh.{{total_paid}} </h6>
							<h6> Balance : Ksh.{{balance}} </h6>
						</div>


						
					</div>






					</div>
					

				</div>


				
			</div>	

			
		</div> 
	</div>






<div style="position:fixed; top: 0; right: 0; width:100%; z-index: 900; padding-top: 100px;" v-if="show_modal">
	
	<div class="container">

		<div class="row">
			<div class="col-md-4"></div>
			<div class="col-md-4">

				<div style="background-color:#fff; padding:20px;" class="shadow-lg rounded">

					<p>Amount to pay : <strong>Ksh.{{ cost }}</strong></p>
					<h5>Payment instructions</h5>
					<p> <i class="bi bi-check-circle color-1"></i> A prompt has been sent to your MPESA phone : <strong>({{this.$store.state.phone}})</strong></p>
					<p> <i class="bi bi-check-circle color-1"></i> Enter PIN to make payment</p>

				<div style="text-align:left;">
						<button class="btn btn-danger" @click="show_modal=false"> <i class="bi bi-x"></i> Close</button>
				</div>

				</div>
				
			</div>
			
		</div>
		
	</div>

</div>






</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			details : [],
			id : '',
			amount : '',
			payment_mode : '',
			comments : '',
			payment_date : '',
			text : 'Submit',
			disabled : false,
			show_modal : false,
			payments : [],
			payment_id : '',
			cost : 0,
			status : '',
			total_paid : 0,
			balance : '',
			show_message : false,
			payment_received : false,
			code : '',
			x : ''	
			
			}
		},

		methods : {

			 async update_code(){
  
        const res = await axios.post(this.$store.state.url+'api/update-code',{

        code : this.code,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

      },

			  start_timer(){

        this.x = setInterval(()=>{
          this.check_payment()
          console.log('checking...')
        },5000)

      },

      stop_timer(){
        clearInterval(this.x)
      },


      async check_payment(){
  
        const res = await axios.post(this.$store.state.url+'api/check-invoice-payment',{

        phone : this.$store.state.phone,
        amount : this.cost,
        invoice_number : this.id,
        membership_number : this.$store.state.membership_number

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

        if(res.length>0){

          //get payment details
          res.forEach((data)=>{
            this.code = data.code;
          })

          this.payment_received = true
          console.log('Payment received')
          this.show_modal = false
          this.stop_timer()
          this.update_code()
          this.invoice_payments()
        }else{
          this.payment_received = false
        }


      },

			async stk_push(){

				this.show_modal = true
				
				const res = await axios.post(this.$store.state.url+'api/mpesa',{

					amount : this.cost,
					phone : this.$store.state.phone,
					desc : 'Account subscription',
					ref : this.$store.state.email

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.start_timer()

			},

			async invoice_payments(){
				this.total_paid = 0

				const res = await axios.get(this.$store.state.url+'api/invoice-payments/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.payments = res 

				res.forEach((data)=>{
					this.total_paid = this.total_paid + parseInt(data.amount)
				})


				if(this.total_paid>=this.cost && this.payments.length>0 ){
					this.status = 'Fully paid'
				}else if(this.total_paid<this.cost && this.payments.length>0 ){
					this.status = 'Partialy paid'
				}else{
					this.status  = 'Not paid'
				}

				this.balance = this.cost - this.total_paid


			},

			async get_details(){

				const res = await axios.get(this.$store.state.url+'api/invoice-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.details = res 

				res.forEach((data)=>{
					this.amount = data.cost
					this.cost = data.cost
				})

				this.invoice_payments()

			}
		
		},

		created(){
			this.id = this.$route.params.id
			this.get_details()

			setTimeout(()=>{
				this.show_message = true
			}, 3000);
			
		}

	}

</script>