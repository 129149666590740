<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Payments</h3>
					</div>


					<div class="shadow rounded p-3">
					

					<div class="table-responsive">

							<table class="table">

								<thead>
									<tr>
									<th> # </th>	<th> Amount </th> <th> Payment mode </th> <th> Payment date </th> <th> Comments </th>
									</tr>
								</thead>

								<tbody>
									
									<tr v-for="p in payments">
										<td>  {{ p.id }}   </td>
										<td> Ksh.{{ p.amount }} </td>
										<td> {{ p.payment_mode }} </td>
										<td> {{ p.payment_date }} </td>
										<td> {{ p.comments }} </td>
									</tr>

								</tbody>
								
							</table>
							
						</div>	
					


					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			payments : []	
			
			}
		},

		methods : {

			async invoice_payments(){

				const res = await axios.get(this.$store.state.url+'api/my-payments/'+this.$store.state.membership_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.payments = res 

			},
		
		},

		created(){
			this.invoice_payments()
		}

	}

</script>