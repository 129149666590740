<template>
	

	<section style="padding-top:20px; padding-bottom: 20px;">
		

		<div class="container">

			<div class="row">
				<div class="col-md-5" style="background-image: url('/images/5206781.jpg'); background-size: cover;  background-position: center;"></div>
				<div class="col-md-7">
					
					<div style="padding: 25px; border: solid 1px #ddd;">
						
						<div style="text-align:center;">
							<img src="/images/KOGS-Logo-1-01.png" style="height: 70px;">
							<h4> <b> {{this.$store.state.organization_name}} </b> </h4>
						<h6>Welcome!</h6>
						<h5>Register</h5>
						</div>

						<form @submit.prevent="stk_push()" method="post">

					<div class="row">

						<div class="col-md-4 mt-2">

							<div>
								<label>Title <span class="text-danger">*</span> </label>
								<select class="form-select" v-model="title" required>
									<option>Mr</option>
									<option>Miss</option>
									<option>Mrs</option>
									<option>Dr</option>
									<option>Prof</option>
								</select>
							</div>
							
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>First name <span class="text-danger">*</span> </label>
								<input v-model="first_name" type="text"  class="form-control" placeholder="Your first name" required>
							</div>
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Last name <span class="text-danger">*</span> </label>
								<input v-model="last_name" type="text"  class="form-control" placeholder="Your last name" required>
							</div>
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Email <span class="text-danger">*</span> </label>
								<input v-model="email" type="email"  class="form-control" placeholder="Your email" required>
							</div>
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Phone number <span class="text-danger">*</span> </label>
								<input v-model="phone" type="text" class="form-control" placeholder="Your phone" required>
							</div>
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Gender </label>
								<select class="form-select" v-model="gender" required>
									<option>Male</option>
									<option>Female</option>
								</select>
							</div>
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Country <span class="text-danger">*</span> </label>
								<input v-model="country" type="text" class="form-control" placeholder="Enter your country" required>
							</div>
						</div>

						
 
						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>County <span class="text-danger">*</span> </label>
								<input v-model="county" type="text" class="form-control" placeholder="Enter your county" required>
							</div>
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Town </label>
								<input v-model="town" type="text" class="form-control" placeholder="Enter your town">
							</div>
						</div>


						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>KMPDC number </label>
								<input v-model="kmpdc" type="text" class="form-control" placeholder="Optional">
							</div>
						</div>


							<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Speciality </label>
								<input v-model="speciality" type="text" class="form-control" placeholder="Enter your speciality" required>
							</div>
						</div>



							<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Organization </label>
								<input v-model="organization" type="text" class="form-control" placeholder="Optional" required>
							</div>
						</div>






						<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Address <span class="text-danger">*</span> </label>
								<input v-model="address" type="text"  class="form-control" placeholder="Your address" required>
							</div>
						</div>


							<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Postal address </label>
								<input v-model="postal_address" type="text" class="form-control" placeholder="Optional">
							</div>
						</div>


							<div class="col-md-4 col-6">
							<div class="mt-2">
								<label>Postal code </label>
								<input v-model="postal_code" type="text" class="form-control" placeholder="Optional">
							</div>
						</div>


						<div class="col-md-12">
							<div class="mt-2">
								<label>Password <span class="text-danger">*</span> </label>
								<input v-model="password" type="password" class="form-control" placeholder="Your password" required>
							</div>
						</div>
						
					</div>

							
							


							

							<div class="mt-3" style="text-align:center;">
								<button class="btn btn-1 w-100" :disabled="disabled">{{text}}</button>
								<p class="mt-3">Already have an account?</p>
								<router-link to="/" class="btn btn-danger w-100">Login</router-link>
							</div>

							

							<div style="text-align: center; padding-top: 30px;">
								<p class="color-3">MMS.All rights reserved</p>
							</div>

						</form>

					</div>

				</div>
				
			</div>
			
		</div>


	</section>



	<div v-if="show_pay" style="position:fixed; right:0; top: 0; width:100%; padding-top:100px;">
		
		<div class="container">

			<div class="row">

				<div class="col-md-4"></div>

				<div class="col-md-4">
					<div style="background-color:#fff; padding:20px; border-radius: 15px;" class="shadow-lg">
						
						<div class="row">
							<div class="col-md-6 col-6">
								Pay
							</div>

							<div class="col-md-6 col-6">
									<div style="text-align:right;">
							<button @click="show_pay=false" class="btn text-danger"> <i class="bi bi-x"></i> </button>
						</div>
							</div>
							
						</div>

					


						<p>A prompt has been sent to your phone</p>
						<p>Enter your MPESA PIN to make payment </p>
						<p>Waiting your to enter PIN...</p>

						<div class="row">

							<div class="col-md-2 col-6">
								<div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
							</div>

							<div class="col-md-10 col-6" style="text-align:right;">
									<button @click="stk_push()" class="btn btn-1">Request  again</button>	
							</div>
							
						</div>

				
					</div>
				</div>
				
			</div>
			
		</div>

	</div>




</template>


<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'

	export default{

		name : 'register',
		data(){
			return {
				first_name : '',
				last_name : '',
				email : '',
				phone : '',
				county : '',
				address : '',
				password : '',
				title : '',
				text : 'Register',
				disabled : false,
				show_pay : false,
				code : '',
				x : '',
				payment_received : false,
				country : '',
				town : '',
				gender : '',
				postal_address : '',
				postal_code : '',
				organization : '',
				speciality : '',
				kmpdc : ''
				
			}
		},

		methods : {

			 async update_code(){
  
        const res = await axios.post(this.$store.state.url+'api/update-code',{

        code : this.code,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

      },

			  start_timer(){

        this.x = setInterval(()=>{
          this.check_payment()
          console.log('checking...')
        },5000)

      },

      stop_timer(){
        clearInterval(this.x)
      },


      async check_payment(){
  
        const res = await axios.post(this.$store.state.url+'api/check-payment',{

        phone : this.phone,
        amount : this.$store.state.cost,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

        if(res.length>0){

          //get payment details
          res.forEach((data)=>{
            this.code = data.code;
          })

          this.payment_received = true
          console.log('Payment received')
          this.stop_timer()
          this.register()
          this.update_code()
        }else{
          this.payment_received = false
        }


      },

				async stk_push(){

				this.show_pay = true
				
				const res = await axios.post(this.$store.state.url+'api/mpesa',{

					amount : this.$store.state.cost,
					phone : this.phone,
					desc : 'Account subscription',
					ref : this.email

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.start_timer()


			},


			async register(){

				this.text = 'Please wait...'
				this.disabled = true
				const res = await axios.post(this.$store.state.url+'api/register-member',{

					title : this.title,
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone,
					county : this.county,
					address : this.address,
					password : this.password,
					title : this.title,
					package : this.$store.state.selected_package,
					year_starting_day : this.$store.state.year_starting_day,
					year_starting_month : this.$store.state.year_starting_month,
					country : this.country,
					town : this.town,
					organization : this.organization,
					postal_code : this.postal_code,
					postal_address : this.postal_address,
					gender : this.gender,
					speciality : this.speciality,
					kmpdc : this.kmpdc

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				if(res=='same email'){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Please note',
					  text: 'A member with that email already exists',
					  showConfirmButton: true,
					  
					})
				this.text = 'Register'
				this.disabled = false
				}else if(res=='same phone'){

					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Please note',
					  text: 'A member with that phone number already exists',
					  showConfirmButton: true,
					  
					})
					this.text = 'Register'
					this.disabled = false

				}else{

					localStorage.setItem('title',this.title)
						this.$store.state.title = this.title

						localStorage.setItem('membership_number',res)
						this.$store.state.membership_number = res

						localStorage.setItem('first_name',this.first_name)
						this.$store.state.first_name = this.first_name

						localStorage.setItem('last_name',this.last_name)
						this.$store.state.last_name = this.last_name

						localStorage.setItem('email',this.email)
						this.$store.state.email = this.email

						localStorage.setItem('phone',this.phone)
						this.$store.state.phone = this.phone

						localStorage.setItem('county',this.county)
						this.$store.state.county = this.county

						localStorage.setItem('address',this.address)
						this.$store.state.address = this.address

					

					localStorage.setItem('my_membership_number',res)
					this.$router.push('/success')

				}
			}


		},

		created(){
			if(!this.$store.state.selected_package){
				this.$router.push('/packages')
			}


		}
	}
</script>

