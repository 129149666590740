import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login'
import Register from '../components/Register'
import Success from '../components/Success'
import Dashboard from '../components/Dashboard'
import Profile from '../components/Profile'
import ProfilePhoto from '../components/ProfilePhoto'
import Packages from '../components/Packages'
import Card from '../components/Card'
import Activities from '../components/Activities'
import ActivityDetails from '../components/ActivityDetails'
import Resources from '../components/Resources'
import Memos from '../components/Memos'
import Invoices from '../components/Invoices'
import InvoiceDetails from '../components/InvoiceDetails'
import Payments from '../components/Payments'
import ChangePackage from '../components/ChangePackage'
import ForgotPassword from '../components/ForgotPassword'
import ResetPassword from '../components/ResetPassword'
import ForgotNumber from '../components/ForgotNumber'
import Renew from '../components/Renew'




const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },

   {
    path: '/register',
    name: 'register',
    component: Register
  },

   {
    path: '/success',
    name: 'success',
    component: Success
  },

     {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },

    {
    path: '/profile',
    name: 'profile',
    component: Profile
  },

   {
    path: '/profile-photo',
    name: 'profile-photo',
    component: ProfilePhoto
  },

  {
    path: '/packages',
    name: 'packages',
    component: Packages
  },


   {
    path: '/card',
    name: 'card',
    component: Card
  },

     {
    path: '/activities',
    name: 'activities',
    component: Activities
  },

   {
    path: '/activity-details/:id',
    name: 'activity-details',
    component: ActivityDetails
  },

   {
    path: '/resources',
    name: 'resources',
    component: Resources
  },

   {
    path: '/memos',
    name: 'memos',
    component: Memos
  },

    {
    path: '/invoices',
    name: 'invoices',
    component: Invoices
  },

  {
    path: '/invoice-details/:id',
    name: 'invoice-details',
    component: InvoiceDetails
  },

    {
    path: '/payments',
    name: 'payments',
    component: Payments
  },


    {
    path: '/change-package',
    name: 'change-package',
    component: ChangePackage
  },

   {
    path: '/reset-password/:id',
    name: 'reset-password',
    component: ResetPassword
  },

   {
    path: '/forgot-number',
    name: 'forgot-password',
    component: ForgotNumber
  },


    {
    path: '/forgot-pwd',
    name: 'forgot-pwd',
    component: ForgotPassword
  },



    {
    path: '/renew',
    name: 'renew',
    component: Renew
  },



  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
