<template>
	
	<section style="padding-top:100px;">
		
		<div class="container">
			<div class="row">
				<div class="col-md-3"></div>
				<div class="col-md-6" style="text-align: center;">
					
					<h1 class="color-1" style="font-size: 60px;"> <i class="bi bi-check-circle"></i> </h1>
					<h4>Account created successfully</h4>
					<h6>Your membership number is : <b>{{ membership_number }}</b>  </h6>
				

					

					<router-link to="/" class="btn btn-1">Login Now</router-link>


				</div>
			</div>
			
		</div>

	</section>




</template>



<script>
	import axios from 'axios'
	export default{
		name : 'success',
		data(){
			return{
				membership_number : localStorage.getItem('my_membership_number'),
			
			}
		},
		methods : {

		}
	}
</script>