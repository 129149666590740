<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div class="row">

						<div class="col-md-6 col-4">
						<div>
						<h3 class="color-3">Change subscription</h3>
					</div>	
						</div>
						
					</div>


					<div>
						

					<div class="shadow rounded p-3">
					
					<div class="row">

						<div class="col-md-3">
							<h6>Current subscription : </h6>
							<h6> <strong> {{this.$store.state.package_name}} </strong> </h6>
							
						</div>

						<div class="col-md-3">
							<label>Downgrade/upgrade to : </label>
							<select class="form-select" v-model="new_package" @change="package_details()">
								<option :disabled="p.name==this.$store.state.package_name" v-for="p in packages" :value="p.id"> {{p.name}} - Ksh.{{p.cost}}/Year </option>
							</select>
							<p v-if="cost" class="mt-2">
								Subscription fee : <strong> Ksh.{{cost}} </strong>
							</p>
						</div>

						<div class="col-md-3" style="padding-top:18px;">
							<button @click="stk_push()" class="btn btn-1 w-100">Subscribe</button>
						</div>
						
					</div>								
	

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 








<div style="position:fixed; top: 0; right: 0; width:100%; z-index: 900; padding-top: 100px;" v-if="show_modal">
	
	<div class="container">

		<div class="row">
			<div class="col-md-4"></div>
			<div class="col-md-4">

				<div style="background-color:#fff; padding:20px;" class="shadow-lg rounded">

					<p>Amount to pay : <strong>Ksh.{{ cost }}</strong></p>
					<h5>Payment instructions</h5>
					<p> <i class="bi bi-check-circle color-1"></i> A prompt has been sent to your MPESA phone : <strong>({{this.$store.state.phone}})</strong></p>
					<p> <i class="bi bi-check-circle color-1"></i> Enter PIN to make payment</p>

				<div style="text-align:left;">
						<button class="btn btn-danger" @click="show_modal=false"> <i class="bi bi-x"></i> Close</button>
				</div>

				</div>
				
			</div>
			
		</div>
		
	</div>

</div>


	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			membership_number : this.$store.state.membership_number,
			text : 'Save changes',
			disabled : false,
			id : this.$store.state.id,
			new_package : '',
			packages : [],
			show_modal : false,
			cost : '',
			package_name : '',
			x : '',
			payment_received : false,
			code : ''
			
			}
		},

		methods : {

			 async update_code(){
  
        const res = await axios.post(this.$store.state.url+'api/update-code',{

        code : this.code,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

      },

			  start_timer(){

        this.x = setInterval(()=>{
          this.check_payment()
          console.log('checking...')
        },5000)

      },

      stop_timer(){
        clearInterval(this.x)
      },


      async check_payment(){
  
        const res = await axios.post(this.$store.state.url+'api/check-payment',{

        phone : this.$store.state.phone,
        amount : this.cost,
        membership_number : this.$store.state.membership_number

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

        if(res.length>0){

          //get payment details
          res.forEach((data)=>{
            this.code = data.code;
          })

          this.payment_received = true
          console.log('Payment received')
          this.show_modal = false
          this.stop_timer()
          this.update_code()
          this.change_package()
        }else{
          this.payment_received = false
        }


      },

			async stk_push(){

				this.show_modal = true
				
				const res = await axios.post(this.$store.state.url+'api/mpesa',{

					amount : this.cost,
					phone : this.$store.state.phone,
					desc : 'Change subscription',
					ref : this.$store.state.email

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.start_timer()
			},

				async package_details(){
				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.new_package).then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.cost = data.cost
					this.package_name = data.name 

				})
				 
			},

			async get_packages(){
				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})

				this.packages = res 
			},

			async change_package(){


				if(!this.new_package){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Select a package',
					  showConfirmButton: true,
					  
					})
					return
				}

				const res = await axios.post(this.$store.state.url+'api/change-package',{
					id : this.$store.state.id,
					new_package : this.new_package,
					membership_number : this.membership_number,
					old_package : this.$store.state.package
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.$store.state.package_name = this.package_name
				localStorage.setItem('package_name',this.package_name)


				this.$store.state.package = this.new_package
				localStorage.setItem('package',this.new_package)

				//get new membership number from the response 
				this.$store.state.membership_number = res
				localStorage.setItem('membership_number',res)

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title : 'Success',
					  text: 'Your subscription has successfully been updated.Your membership number is : '+res,
					  showConfirmButton: true,
					  
					})

				this.$router.push('/profile')

				
			}
		
		},

		created(){

			this.get_packages()

		}

	}

</script>