<template>
	
	<div class="container" style="padding-top:100px;">


		<div class="row">

			<div class="col-md-2"></div>
			
				<div class="col-md-4" style="background-image: url('/images/happy-latin-office-employee-texting-message(2).jpg'); background-size: cover; background-position: center;"></div>
			<div class="col-md-4">

				<div style="text-align:center; border:solid 1px #ddd; padding:50px 10px 50px 10px;">
					
					<h3>{{this.$store.state.organization_name}}</h3>

					<h3> <i class="bi bi-info-circle color-1"></i> </h3>

					<p>
						You cannot login because your account has expired
					</p>

					<h6>Package Details:</h6>
					<p>Package name : {{package_name}} - Ksh.{{amount}}/year </p>

					<div>
						<button @click="stk_push()" class="btn btn-1" style="margin-right:10px;">Renew</button>
						<router-link to="/" class="btn btn-3">Back To Login</router-link>
					</div>

				</div>
				
			</div>
			
		</div>
		
	</div>




		<div v-if="show_modal" style="position:fixed; right:0; top: 0; width:100%; padding-top:100px;">
		
		<div class="container">

			<div class="row">

				<div class="col-md-4"></div>

				<div class="col-md-4">
					<div style="background-color:#fff; padding:20px; border-radius: 15px;" class="shadow-lg">
						
						<div class="row">
							<div class="col-md-6 col-6">
								Pay
							</div>

							<div class="col-md-6 col-6">
									<div style="text-align:right;">
							<button @click="show_modal=false" class="btn text-danger"> <i class="bi bi-x"></i> </button>
						</div>
							</div>
							
						</div>

					


						<p>A prompt has been sent to your phone</p>
						<p>Enter your MPESA PIN to make payment </p>
						<p>Waiting your to enter PIN...</p>

						<div class="row">

							<div class="col-md-2 col-6">
								<div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
							</div>

							<div class="col-md-10 col-6" style="text-align:right;">
									<button @click="stk_push()" class="btn btn-1">Request  again</button>	
							</div>
							
						</div>

				
					</div>
				</div>
				
			</div>
			
		</div>

	</div>



</template>


<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'
	
	export default{
		name : 'renew',

		data(){
			return{

				show_modal : false,
				payment_received : false,
				x : '',
				amount : 0,
				package_name : '',
				code : ''

			}
		},


		methods : {

			async renew_account(){
				const res = await axios.post(this.$store.state.url+'api/renew-account',{
					membership_number : this.$store.state.membership_number,
					package : this.$store.state.package,
					amount : this.amount
				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.$store.state.expires_on = res 
				localStorage.setItem('expires_on', res)

		
						Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title : 'Success',
					  text: 'Account renewed successfully',
					  showConfirmButton: true,
					  
					})
					this.$router.push('/dashboard')

					
			},

			 async update_code(){
  
        const res = await axios.post(this.$store.state.url+'api/update-code',{

        code : this.code,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

      },

			  start_timer(){

        this.x = setInterval(()=>{
          this.check_payment()
          console.log('checking...')
        },5000)

      },

      stop_timer(){
        clearInterval(this.x)
      },


      async check_payment(){
  
        const res = await axios.post(this.$store.state.url+'api/check-payment',{

        phone : this.$store.state.phone,
        amount : this.amount,

        }).then(function(response){
          return response.data
        }).catch(function(error){
          console.log(error)
        })

        if(res.length>0){

          //get payment details
          res.forEach((data)=>{
            this.code = data.code;
          })

          this.payment_received = true
          console.log('Payment received')
          this.stop_timer()
          this.renew_account()
          this.update_code()
        }else{
          this.payment_received = false
        }


      },

				async stk_push(){

				this.show_modal = true
				
				const res = await axios.post(this.$store.state.url+'api/mpesa',{

					amount : this.amount,
					phone : this.$store.state.phone,
					desc : 'Renew Account',
					ref : this.$store.state.email

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.start_timer()


			},


			async package_details(){

				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.$store.state.package).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.amount = data.cost
					this.package_name = data.name 
				})

			}



		},


		created(){

			//get package details 
			this.package_details()

		}



	}

</script>