<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Photo</h3>
					</div>


					<div class="shadow rounded p-3">

									
					<form @submit.prevent="change_photo()" enctype="multipart/form-data">
						
						<div class="row">
							

							<div class="col-md-4 mt-2">
								<label>Photo</label>
								<input  type="file"  class="form-control" @change="get_image">
							</div>

							<div class="col-md-12 mt-3">

								<router-link to="/profile" class="btn btn-2" style="margin-right: 10px;">Go back</router-link>
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			photo : '',
			id : this.$store.state.id,
			text : 'Save changes',
			disabled : false	
			
			}
		},

		methods : {

			get_image(event){
				this.photo = event.target.files[0]
				
			},

			async change_photo(){
				if(!this.photo){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title: "Error",
					  text : 'Photo is required',
					  showConfirmButton: false,
					  timer: 1500
					});
					return
				}

				 const config = {
      header : {
        'Content-Type' : 'multipart/form-data'
      }
    }

				this.text = 'Please wait...'
				this.disabled = true

				let form_data = new FormData()
				form_data.append('id',this.$store.state.id)
				form_data.append('photo',this.photo)


				const res = await axios.post(this.$store.state.url+'api/change-photo',form_data,config).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				
					Swal.fire({
					  position: "center",
					  icon: "success",
					  title: "Success",
					  text : 'Photo uploaded successfully',
					  showConfirmButton: true,
					});

					this.$store.state.photo = res
					localStorage.setItem('photo',res) 
				

			this.text = 'Save changes'
			this.disabled = false 
			this.photo = ''

				
			}
		
		}

	}

</script>