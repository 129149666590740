<template>


	<!-- for header part -->
	<header> 

		<div class="logosec"> 
			<!--<img src="/assets/images/new-logo.png" class="logo">--> 
			<h6> {{this.$store.state.organization_name}} </h6>
			<button class="btn btn-lg menuicn color-1" id="menuicn" @click="header()"> <i class="bi bi-grid-fill"></i> </button> 
		</div> 

		<div class="searchbar"> 
			<router-link style="margin-right: 10px;" to="/resources" class="btn btn-2"> <i class="bi bi-database-gear"></i> Resources</router-link>
			<router-link style="margin-right: 10px;" to="/activities" class="btn btn-2"> <i class="bi bi-list"></i> Activities</router-link>
			<router-link to="/card" style="margin-right: 10px;" class="btn btn-2"> <i class="bi bi-person-vcard"></i> Card</router-link>
			<router-link to="/profile" class="btn btn-2"> <i class="bi bi-gear"></i> Profile</router-link>

		</div> 

		<div class="message"> 
			<div class="circle"></div>
			<div class="dp">

			</div> 
			Hi,{{this.$store.state.full_name}}
			<router-link to="/profile">

				<label v-if="this.$store.state.photo!='na'" class="profile-icon" :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/profile-photos/'+this.$store.state.photo+')' } "></label>

		
		</router-link>

			<router-link v-if="this.$store.state.photo=='na'" class="btn btn-lg" to="/profile"><i class="bi bi-person"></i>  </router-link>
			 
		</div> 

	</header> 

	


</template>

<script>
	
	export default{
		name : 'header',
		methods : {
			header(){
		let menuicn = document.querySelector(".menuicn"); 
let nav = document.querySelector(".navcontainer"); 
nav.classList.toggle("navclose"); 


			}
		},

		created(){
			
		}
	}

</script>