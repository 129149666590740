<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Memos</h3>
					</div>


					<div class="shadow rounded p-3">


					<div class="memo-design" v-for="m in memos">

						<h6> {{m.subject}} </h6>
						<p>
							{{m.message}}
						</p>

						<h6 class="color-3"> <i class="bi bi-calendar-check"></i> {{m.created}} </h6>
						
					</div>


					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			memos : [],
			
			}
		},

		methods : {

			async get_memos(){

				const res = await axios.get(this.$store.state.url+'api/get-memos').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

			this.memos = res 

			}
		
		},

		created(){
			this.get_memos()
		}

	}

</script>