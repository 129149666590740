<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						


					<div class="row">

						<div class="col-md-6 col-4">
						<div>
						<h3 class="color-3">Profile</h3>
					</div>	
						</div>

						<div class="col-md-6 col-8" style="text-align:right;">
							<router-link to="/change-package" class="btn btn-outline-danger">Downgrade/Upgrade</router-link>
						</div>
						
					</div>


					<div class="shadow rounded p-3">
						
					<div class="alert alert-info" v-if="this.$store.state.photo=='na'">
					<div class="row">
						<div class="col-md-8">
							<p> <strong>Note : </strong> You have not uploaded your profile photo yet</p>
						</div>
						<div class="col-md-4" style="text-align:right;">
							<router-link to="/profile-photo" class="btn btn-1">Upload now</router-link>
						</div>
					</div>
					</div>


					<div style="background-color: #fafafa; padding: 10px; border-radius: 15px;">


						<div class="row">

						<div class="col-md-2">
							<div v-if="this.$store.state.photo!='na'" style="text-align:left;">
						
						<label class="profile-photo" :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/profile-photos/'+this.$store.state.photo+')' } "></label>
					</div>
						</div>

						<div class="col-md-6">
							<h3>  {{full_name}} </h3>
							<h6> {{email}} </h6>
							<h6 class="color-3">Member since : {{created}}</h6>
						</div>

						<div class="col-md-4 mt-2" style="text-align:left;">
							<router-link to="/activities" class="btn btn-1" style="margin-right: 5px;">Activities </router-link>

							<router-link v-if="this.$store.state.photo!='na'" to="/profile-photo" class="btn btn-2">Change photo + </router-link>
							<p class="mt-3"> Membership package : <span class="badge bg-success">{{this.$store.state.package_name}}</span> </p>
						</div>
						
					</div>
						
					</div>


					


	<div class="row" style="padding-top:20px;">

		<div class="col-md-8">
			<div style="text-align:left;">
			
					<h6 class="mt-2"> Membership number : <b>{{this.$store.state.membership_number}}</b> </h6>
		</div>
		</div>


	<div class="col-md-4" style="text-align:left;">
		Status : <span class="badge bg-info">{{this.$store.state.status}}</span>, 
		Expires : <span class="badge bg-danger">{{this.$store.state.expires_on}}</span>
	</div>
		
	</div>


							
					
					<form @submit.prevent="change_profile()">
						
						<div class="row">
							<div class="col-md-4 mt-2">
								<label>Title</label>
								<select class="form-select" v-model="title" required>
									<option>Mr</option>
									<option>Miss</option>
									<option>Mrs</option>
									<option>Dr</option>
									<option>Prof</option>
								</select>
							</div>

							<div class="col-md-4 mt-2">
								<label>First name</label>
								<input  type="text" v-model="first_name" class="form-control">
							</div>

							<div class="col-md-4 mt-2">
								<label>Last name</label>
								<input  type="text" v-model="last_name" class="form-control">
							</div>


							<div class="col-md-4 mt-2">
								<label>Email</label>
								<input  type="text" v-model="email" class="form-control">
							</div>

							<div class="col-md-4 mt-2">
								<label>Phone</label>
								<input  type="text" v-model="phone" class="form-control">
							</div>

							<div class="col-md-4 mt-2">
								<label>County</label>
								<input  type="text" v-model="county" class="form-control">
							</div>

							<div class="col-md-4 mt-2">
								<label>Address</label>
								<input  type="text" v-model="address" class="form-control">
							</div>

							<div class="col-md-4 mt-2">
								<label>Password</label>
								<input type="password" v-model="password" class="form-control" placeholder="**********">
							</div>


							<div class="col-md-12 mt-3">
								
								<button class="btn btn-1" :disabled="disabled"> {{text}} </button>
							</div>

						</div>

					</form>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			membership_number : this.$store.state.membership_number,
			title : this.$store.state.title,
			first_name : this.$store.state.first_name,
			last_name : this.$store.state.last_name,
			email : this.$store.state.email,
			phone : this.$store.state.phone,
			county : this.$store.state.county,
			address : this.$store.state.address,
			password : '',
			text : 'Save changes',
			disabled : false,
			id : this.$store.state.id,
			created : this.$store.state.created,
			full_name : this.$store.state.full_name
			
			}
		},

		methods : {
			async change_profile(){

				if(!this.title){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Title cannot be blank!',
					  showConfirmButton: true,
					  
					})
					return
				}


				if(!this.first_name){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'First name cannot be blank!',
					  showConfirmButton: true,
					  
					})
					return
				}


				if(!this.last_name){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Last name cannot be blank!',
					  showConfirmButton: true,
					  
					})
					return
				}


				if(!this.email){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Email cannot be blank!',
					  showConfirmButton: true,
					  
					})
					return
				}

				if(!this.phone){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Phone number cannot be blank!',
					  showConfirmButton: true,
					  
					})
					return
				}


				if(!this.title){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'County cannot be blank!',
					  showConfirmButton: true,
					  
					})
					return
				}

				if(!this.title){
					Swal.fire({
					  position: "center",
					  icon: "error",
					  title: "Error",
					  text : 'Address cannot be blank!',
					  showConfirmButton: true,
					  
					})
					return
				}

				this.text = 'Please wait...'
				this.disabled = true


				const res = await axios.post(this.$store.state.url+'api/change-profile',{
					id : this.$store.state.id,
					title : this.$store.state.title,
					first_name : this.first_name,
					last_name : this.last_name,
					email : this.email,
					phone : this.phone,
					county : this.county,
					address : this.address,
					password : this.password,

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				Swal.fire({
					  position: "top-center",
					  icon: "success",
					  title: "Changes saved successfully",
					  showConfirmButton: false,
					  timer: 1500
					});

			this.text = 'Save changes'
			this.disabled = false 

						localStorage.setItem('title',this.title)
						this.$store.state.title = this.title

						localStorage.setItem('first_name',this.first_name)
						this.$store.state.first_name = this.first_name

						localStorage.setItem('last_name',this.last_name)
						this.$store.state.last_name = this.last_name

						localStorage.setItem('email',this.email)
						this.$store.state.email = this.email

						localStorage.setItem('phone',this.phone)
						this.$store.state.phone = this.phone

						localStorage.setItem('county',this.county)
						this.$store.state.county = this.county

						localStorage.setItem('address',this.address)
						this.$store.state.address = this.address

						localStorage.setItem('full_name',this.title+','+this.first_name+' '+this.last_name)
						this.$store.state.full_name = this.title+','+this.first_name+' '+this.last_name

						this.full_name = this.$store.state.full_name


				
			}
		
		},

		created(){

		}

	}

</script>