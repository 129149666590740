<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div>
						<h3 class="color-3">Membership card</h3>
					</div>


					<div class="shadow rounded p-3">
						
					<div class="alert alert-info" v-if="this.$store.state.photo=='na'">
					<div class="row">
						<div class="col-md-8">
							<p> <strong>Note : </strong> You have not uploaded your profile photo yet</p>
						</div>
						<div class="col-md-4" style="text-align:right;">
							<router-link to="/profile-photo" class="btn btn-1">Upload now</router-link>
						</div>
					</div>
					</div>


				<!-- card -->



			<div  id="badge" style="margin-top: 30px;" v-if="this.$store.state.photo!='na'">

					<div  style="border: solid 1px #ddd; padding:10px; background-color: #fff; background-size: cover; background-position: top; width: 50%; float: left; ">

						<div style="width: 25%; float: left;">
							<div :style=" { backgroundImage : 'url('+this.$store.state.url+'assets/profile-photos/'+this.$store.state.photo+')', width:'100%', height:'150px', backgroundSize : 'cover', backgroundPosition : 'center', borderRadius: '0', border : 'solid 1px #ddd' } "></div>
						
						</div>

						<div style="text-align:left; width: 50%; float: left; padding-left: 20px;">
							<h6 class="color-3"> {{this.$store.state.organization_name}} </h6>	
							
							<h5>{{this.$store.state.title}}.{{this.$store.state.first_name}} {{this.$store.state.last_name}} </h5>
							<h5>{{this.$store.state.membership_number}}</h5>

							<div style="border-top:solid 1px #ddd; margin-top: 10px; padding-top: 5px;">
								<p> Membership : {{package_name}} <br />
								Member since : {{this.$store.state.created}}</p>
							</div>
							
						</div>


						<div style="text-align:left; width: 15%; float: left; padding-left: 20px;">
							<div v-html="this.$store.state.qr_code"></div>
							<p style="background-color:#b7006c; margin-top:10px; padding:5px; color:#fff; width: 100px; text-align:center;">CARD</p>
						</div>
					
						
					</div>

					  </div>


					  


					  <div v-if="this.$store.state.photo!='na'" style="clear: both; padding-top: 20px;">
					  	<button v-print="'#badge'"  class="btn btn-2"> <i class="bi bi-printer"></i> Print</button>
					  </div>








					</div>

					</div>
					

				</div>


				
			</div>













		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	import print from 'vue3-print-nb'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{

				package_name : '',
				
			
			}
		},

			directives: {
    print   
},

		methods : {


			async package_details(){

				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.$store.state.package).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				res.forEach((data)=>{
					this.package_name = data.name 
				
				})

			},

			print_now(printpage) {
            var headstr = "<html><head><title>Badges</title></head><body'>";
            var footstr = "</body>";
            var newstr = document.all.item(printpage).innerHTML;
            var oldstr = document.body.innerHTML;
            document.body.innerHTML = headstr + newstr + footstr;
            window.print();
            document.body.innerHTML = oldstr;
            return true;
        },




		
		},

		created(){

			this.package_details()
		}

	}

</script>