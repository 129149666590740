<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 


			<div class="row">

				<div class="col-md-12 mt-3">


					<div>
						
						<div class="row">

							<div class="col-md-4">
								<div>
						<h3 class="color-3">Invoices({{invoices.length}})</h3>
					</div>
							</div>


							<div class="col-md-8">
								<h6>Filter</h6>

								<form @submit.prevent="filter_invoice()">
									
									<div class="row">
 
										<div class="col-md-4 mb-2">
											<label>By Year</label>
											<select class="form-select" v-model="year">
												<option v-for="y in years">{{y}}</option>
											</select>
										</div>


										<div class="col-md-4 mb-2">
											<label>By Status</label>
											<select class="form-select" v-model="status">
												<option>Paid</option>
												<option>Not paid</option>
											</select>
										</div>

										<div class="col-md-4 mb-2">
											<br />
											<button style="margin-right:2px;" class="btn btn-1 "> <i class="bi bi-search"></i> Search</button>
											<button @click="get_invoices()" type="button" class="btn btn-1 "> <i class="bi bi-eye"></i> View all</button>
										</div>
										
									</div>

								</form>

							</div>
							
						</div>


					<div class="shadow rounded p-3">
						
					<div class="table-responsive">

						<table class="table">

							<thead>
								<tr>
								<th> # </th>  <th> Invoice year </th> <th> Amount </th> <th> Status </th>  <th>Invoice date</th> <th>Comments</th> <th> Actions... </th>	
								</tr>
							</thead>


							<tbody>
								
								<tr v-for="i in invoices">
									<td> <router-link :to=" { name: 'invoice-details', params : { id : i.invoice_number } } "> {{i.invoice_number}} </router-link> </td>
									<td> {{i.year}} </td>
									<td> Ksh.{{i.cost}} </td>
									<td> 

									<p class="badge bg-success" v-if="i.status=='Paid'">
										{{i.status}}
									</p>

									<p class="badge bg-danger" v-if="i.status=='Not paid'">
										{{i.status}}
									</p>

									</td>

									<td> {{i.created}} </td>
									<td> {{i.comments}} </td>
									<td>
										<router-link class="btn btn-outline-success" :to=" { name: 'invoice-details', params : { id : i.invoice_number } } "> <i class="bi bi-eye"></i> View </router-link>
									</td>
								</tr>

							</tbody>
							
						</table>
						

					</div>

					</div>

					</div>
					

				</div>


				
			</div>


		

		

			
		</div> 
	</div> 
	


</template>


<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from "axios"
	import Swal from 'sweetalert2'
	export default{
		name : 'header',
		components : {SideBar,TopBar},
		data(){
			return{
			
			invoices : [],
			years : [],
			year : '',
			status : ''	
			
			}
		},

		methods : {

			async filter_invoice(){
				if(!this.year && !this.status){
					return
				}

				const res = await axios.post(this.$store.state.url+'api/filter-my-invoices',{

					membership_number : this.$store.state.membership_number,
					status : this.status,
					year : this.year

				}).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.invoices = res 

			},

			get_years(){
				let counter = 40
				let i = 0
				let first_year = 2000
				while(i < counter){
					
					first_year = first_year+1
					this.years = this.years.concat(first_year)
					i ++
				}
			},

			async get_invoices(){

				const res = await axios.get(this.$store.state.url+'api/my-invoices/'+this.$store.state.membership_number).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.invoices = res 

			}
		
		},

		created(){
			this.get_years()
			this.get_invoices()

		}

	}

</script>