<template>
	

	<section style="padding-top:100px; padding-bottom:50px;">
		
		<div class="container">

			<div class="row">
				<div class="col-md-2"></div>

<div class="col-md-4" style="background-image: url('/images/organic-flat-doctors-nurses-illustration_23-2148901758.jpg'); background-size: cover; background-position: center;"></div>
				<div class="col-md-4">


					<div  style="text-align:center; background-color: #fff; padding: 25px; border: solid #ddd 1px; ">

						<img src="/images/KOGS-Logo-1-01.png" style="height: 70px;">

						<h4><b> {{this.$store.state.organization_name}}</b> </h4>
					<h5>Choose a package</h5>

 
					<div class="row mt-3">

						<div class="col-md-4 p-3" v-for="p in packages">

							<div style="border-bottom: solid 2px #ffe9f4; padding-bottom: 5px;">

								<label class="color-3">{{p.name}}</label>
							<h6> <b> Ksh.{{p.cost}}/Yr</b> </h6>
							<input type="radio" v-model="selected_package" :value="p.id">
								
							</div>

							
						</div>
						
					</div>

					<div style="padding-top:10px;">
						<router-link to="/" class="btn btn-3" style="margin-right: 10px;">Previous</router-link>
						<button @click="select_package()" class="btn btn-1">Proceed  </button>
					</div>

					<div style="text-align: center; padding-top: 30px;">
								<p class="color-3">MMS.All rights reserved</p>
							</div>



						
					</div>

										
					
					


				</div>
				
			</div>
			
		</div>

	</section>
		







</template>


<script>
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'packages',
		data(){
			return{
				packages : [],
				selected_package : ''
			}
		},

		methods : {

			select_package(){
				if(!this.selected_package){
					Swal.fire({
					  position: "top-center",
					  icon: "error",
					  title : 'Error',
					  text: 'Select a package first',
					  showConfirmButton: true,
					  
					})
					return
				}
				this.$store.state.selected_package = this.selected_package
				localStorage.setItem('selected_package',this.selected_package)
				this.package_details()
				this.$router.push('/register')
			},

			async get_packages(){
				const res = await axios.get(this.$store.state.url+'api/get-packages').then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})

				this.packages = res 
			},

			async package_details(){
				const res = await axios.get(this.$store.state.url+'api/package-details/'+this.$store.state.selected_package).then(function(response){
					return response.data 
				}).catch(function(error){
					console.log(error)
				})

				res.forEach((data)=>{
					this.$store.state.cost = data.cost 
					localStorage.setItem('cost',data.cost)
				})


				
			}

		},
		created(){
			this.get_packages()
			if(this.$store.state.selected_package){
				this.selected_package = this.$store.state.selected_package
			}
		}
	}
</script>

